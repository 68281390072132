import { ReactElement } from 'react'
import terms from 'common/terms'
import ProgramPage from 'pages/Programs/ProgramPage'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import AppsPage from 'pages/Apps/AppsPage'
import AllProgram from 'pages/AllProgram/AllProgram'
import Home from '../pages/Home/Home'
import AllApp from '../pages/AllApp/AllApp'

export type NavElement = {
  title: string;
  path: string;
  component: () => ReactElement;
  role: Array<string>;
}

export const navRoutes: NavElement[] = [
  {
    title: terms.navigation.applications.name,
    path: '/',
    component: AllApp,
    role: [],
  },
]

export const defineNavRoutes = ():NavElement[] => {
  const { programs, services } = useSelector((state: RootState) => state.programs)
  const newRoutes:NavElement[] = []

  const organisation = [...programs, ...services]
  programs.map(program => (
    newRoutes.push({
      title: '',
      path: `/${program.slug}/applications/:applicationName/`,
      component: AppsPage,
      role: [],
    })
  ))
  organisation.map(program => (
    newRoutes.push({
      title: program.name,
      path: `/${program.slug}/`,
      component: ProgramPage,
      role: [],
    })
  ))
  return navRoutes.concat(newRoutes)
}
