import { RootState } from 'Store'
import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import nextId from 'react-id-generator'
import { ApplicationProgramType, ProgramType } from 'reducers/programs/types'
import { Skeleton } from '@mui/material'
import AppExcerpt from 'pages/Home/HomeApps/AppExcerpt'
import AppFilters from './AppFilters'
import './allApp.scss'

export default function AllApp(): ReactElement {
  const { programs, filters } = useSelector((state: RootState) => state.programs)
  const { appPermissions } = useSelector((state: RootState) => state.user)
  const { loading } = useSelector((state: RootState) => state.programs)

  const filterApplications = (application: ApplicationProgramType) => {
    if (filters.currentAccess && !appPermissions.includes(application.access_perm)) {
      return false
    }
    return true
  }

  const createProgramArray = () => {
    if (filters.program) {
      return ([programs.find((program: ProgramType) => program.name === filters.program)]).map(program => (
        program?.apps.filter((application: ApplicationProgramType) => filterApplications(application)).map(app => (
          <AppExcerpt application={app} key={nextId()} />
        ))
      ))
    }
    return programs.map(program => (
      program?.apps.filter((application: ApplicationProgramType) => filterApplications(application)).map(app => (
        <AppExcerpt application={app} key={nextId()} />
      ))
    ))
  }

  return (
    <div className="all-app">
      <div className="all-app-tilelist">
        <div className="all-app-filters">
          {
            loading ? (
              <Skeleton variant="rounded" height={84} className="skeleton-filters" />
            ) : (
              <></>
            )
          }
        </div>
        {
          loading ? (
            <>
              <Skeleton variant="rounded" height={359} className="skeleton-tile" />
              <Skeleton variant="rounded" height={359} className="skeleton-tile" />
              <Skeleton variant="rounded" height={359} className="skeleton-tile" />
            </>
          ) : (
            createProgramArray()
          )
        }
      </div>
    </div>
  )
}
