import {
  useEffect, Suspense, ReactElement, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Redirect,
  Route,
  Router, Switch,
} from 'react-router-dom'
import { RootState } from 'Store'
import AppsPage from 'pages/Apps/AppsPage'
import Loader from 'components/Loader'
import TabList from 'components/TabList/TabList'
import TopBar from 'components/TopBar/TopBar'
import history from 'customHistory'
import AccessDenied from 'components/AccessDenied/AccessDenied'
import 'App.scss'
import commonTerms from 'common/terms'
import { defineNavRoutes, navRoutes } from 'utils/navRoutes'
import { auth } from '@osrdata/app_core'
import fetchData from 'reducers/programs/programs.thunk'

const APP_NAME = 'PDA'
const ACCESS_PERM = 'PDA::ACCESS'

export default function App(): ReactElement {
  const dispatch = useDispatch()
  const { isLogged, appPermissions, isLoading } = useSelector((state: RootState) => state.user)

  const [accessDenied, setAccessDenied] = useState(false)
  const routes = navRoutes

  useEffect(() => {
    dispatch(auth.attemptLoginOnLaunch())
  }, [])

  useEffect(() => {
    if (isLogged && appPermissions.length > 0) {
      dispatch(fetchData())
      if (!appPermissions.includes(ACCESS_PERM)) {
        setAccessDenied(false)
      }
    }
  }, [isLogged, appPermissions])

  return (
    <>
      <Suspense fallback={<Loader />}>
        <Router history={history}>
          <TopBar
            appName={APP_NAME}
            hasAccess={!accessDenied}
          />
          {(!isLoading && isLogged) && (accessDenied ? <AccessDenied /> : (
            <div id="app">
              <Switch>
                {routes.map(route => (
                  <Route
                    key={route.path}
                    path={route.path}
                  >
                    <route.component />
                  </Route>
                ))}
                <Redirect to={commonTerms.navigation.accueil.path} />
              </Switch>
            </div>
          ))}
        </Router>
      </Suspense>
    </>
  )
}
